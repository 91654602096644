import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { Button, Panel, FormGroup, Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import { updateListRibbon } from '../services';
import queryString from 'query-string';
import { IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import SearchContent from './SearchContent';

function SubNavigation(props) {
  const ribbons = props.page && (props.page.ribbons || null);
  const { dpUpdateListRibbon, listRibbonTarget, match } = props;
  const [isOpen, setOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [listCategoryTarget, setListCategoryTarget] = useState(listRibbonTarget || []);
  const categoryEl = useRef(null);
  let tmpArrayCategory = [];
  let tmpArrayCategoryTarget = [];
  let paramQuery = queryString.parse(props.location.search, { decode: false }) || {};

  useEffect(
    () => {
      if (!ribbons || props.page.display_style !== 'YOUTUBE') {
        return;
      }
      ribbons.map((items, index) => {
        if (Array.isArray(items.items) && !items.items.length) return;
        let data = {
          id: items.id,
          name: items.name,
          slug: items.slug,
          checked: false,
        };
        if (listRibbonTarget.length === 0 && items.is_default_display) {
          data = {
            ...data,
            checked: true,
          };
          tmpArrayCategoryTarget.push(data);
        }

        if (listRibbonTarget.length) {
          let checkInArray = listRibbonTarget.findIndex(obj => obj.slug === items.slug);
          if (checkInArray !== -1) {
            data = {
              ...data,
              checked: true,
            };
            tmpArrayCategoryTarget.push(data);
          }
        }
        tmpArrayCategory.push(data);
      });
      dpUpdateListRibbon(tmpArrayCategoryTarget);
      setListCategoryTarget(tmpArrayCategoryTarget);
      setCategory(tmpArrayCategory);
    },
    [ribbons],
    [match.params && match.params.keyword],
  );

  useEffect(
    () => {
      if (paramQuery.viewmore) {
        setListCategoryTarget(listRibbonTarget);
        let tmpCategory = category;
        const objIndex = tmpCategory.findIndex(
          obj => obj.slug === listRibbonTarget[listRibbonTarget.length - 1].slug,
        );
        tmpCategory[objIndex] = {
          ...tmpCategory[objIndex],
          checked: true,
        };
      }
    },
    [paramQuery],
    [match.params && match.params.keyword],
  );

  function selectCategory(idTarget, event) {
    let checked = event.target.checked;
    let tmpCategory = category;
    idTarget = {
      ...idTarget,
      checked: checked,
    };

    const objIndex = tmpCategory.findIndex(obj => obj.slug === idTarget.slug);
    tmpCategory[objIndex] = {
      ...idTarget,
    };
    setCategory(tmpCategory);

    if (checked) {
      dpUpdateListRibbon([...listCategoryTarget, idTarget]);
      return setListCategoryTarget([...listCategoryTarget, idTarget]);
    }

    let filteredItems = listCategoryTarget.filter(item => {
      return item.slug !== idTarget.slug;
    });
    dpUpdateListRibbon(filteredItems);
    return setListCategoryTarget(filteredItems);
  }

  return (
    <div className="v-ytbPage--navigation">
      <div className="v-ytbPage--navigation-btn-category" onClick={() => setOpen(!isOpen)}>
        <i className="fa fa-indent" aria-hidden="true" />
      </div>
      <Panel ref={categoryEl} className="v-ytbPage--navigation--category" expanded={isOpen}>
        <Panel.Collapse>
          <Panel.Heading>
            Category
            <Button onClick={() => setOpen(!isOpen)}>
              <IonIcon icon={closeOutline} />
            </Button>
          </Panel.Heading>
          <hr />
          <Panel.Body>
            <FormGroup className="v-ytbPage--navigation--form">
              {category.length > 0 &&
                category.map((items, index) => {
                  return (
                    <Checkbox
                      key={index}
                      onChange={event => selectCategory(items, event)}
                      checked={items.checked}
                      className={`${
                        listCategoryTarget.length === 1 &&
                        items.slug === listCategoryTarget[0].slug &&
                        'v-ytbPage--navigation--disable'
                      }`}
                    >
                      {items.name}
                    </Checkbox>
                  );
                })}
            </FormGroup>
          </Panel.Body>
        </Panel.Collapse>
      </Panel>
      {listCategoryTarget.length > 0 &&
        listCategoryTarget.map((items, index) => {
          return (
            <Link
              key={index}
              to={{
                pathname: `/youtube/${match.params.slug}`,
                search: `?category=${items.slug}&${props.page.search}`,
              }}
              className={`${
                ((!paramQuery.category && index === 0) || items.slug === paramQuery.category) &&
                !match.params.keyword &&
                'active'
              }`}
            >
              {items.name}
            </Link>
          );
        })}
      <SearchContent />
    </div>
  );
}

const mapStateToProps = state => ({
  menu: state.youtubePage.menu,
  page: state.youtubePage.page,
  account: state.auth.account,
  listRibbonTarget: state.youtubePage.listRibbonTarget,
});

const mapDispatchToProps = {
  dpUpdateListRibbon: data => updateListRibbon(data),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubNavigation)),
);
