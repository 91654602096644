import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { search } from '../services';
import { Loader } from '@/shared/components/loader/Loader';
import '../../search/views/searchPage.css';
import { withTranslation } from 'react-i18next';
import { RowStyled } from '../../search/views/styled';
import { withRouter } from 'react-router-dom';
import SearchResult from '../../search/components/result';
import Suggest from '../../search/components/suggest';
import Tag from '../../search/components/tag';
import queryString from 'query-string';

const LoadingOverlay = () => {
  return (
    <RowStyled className="v-searchPage--loading">
      <Loader type="Oval" color="#000000" height="35" width="35" />
    </RowStyled>
  );
};

const Search = props => {
  const { match, t, dispatchSearch } = props;
  const keyword = (match.params && match.params.keyword) || '';
  const [resMessage, setMessage] = useState(null);
  const [isLoadingSearch, setLoading] = useState(true);
  const [entities, setEntity] = useState({ items: [] });
  const [search, setSearch] = useState('');
  const [genre, setGenre] = useState([]);
  const [related, setRelated] = useState([]);
  let page = 0;
  let limit = 50;

  useEffect(() => {
    let message = t('search.message');
    const value = decodeURIComponent(keyword.trim());
    setMessage(message + '"' + value + `"`);
    setSearch(value);
    getSearch(value);
  }, [match.params.keyword.trim()]);

  const getSearch = keyword => {
    setLoading(true);
    let message = t('search.message');
    let param = {
      keyword: keyword,
      page,
      limit,
    };
    dispatchSearch(param, match.params.slug)
      .then(res => {
        setEntity(res);
        setMessage(`${message} "${keyword.trim()}"`);
        setSearch(keyword.trim());
        setGenre(res.items[0] ? res.items[0].genre : []);
        setRelated(res.suggestion_items);
      })
      .catch(error => {
        setMessage(
          error.message === 'Unexpected token < in JSON at position 0'
            ? message + `"` + keyword.trim() + `"`
            : error.message,
        );
        setEntity({ items: [] });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (isLoadingSearch) return <LoadingOverlay />;
  return (
    <RowStyled>
      <div className="v-searchPage--container">
        <div className="v-searchPage--title">
          {entities.items.length !== 0 ? (
            <div style={{ marginTop: '5rem' }}>
              {t('search.result', {
                number: entities.items.length,
                name: search,
              })}
            </div>
          ) : null}
        </div>
        <div className="v-searchPage--row">
          <SearchResult
            isLoadingSearch={isLoadingSearch}
            entities={entities}
            resMessage={resMessage}
          />
        </div>
        <Suggest related={related} />
        <hr />
        <Tag genre={genre} />
      </div>
    </RowStyled>
  );
};

Search.defaultProps = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  dispatchSearch: (params, pageSlug) => search(params, pageSlug),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Search)));
