import React, { useRef, useEffect, useState } from 'react';
import LeftSite from '../component/leftSite';
import VerticalRibon from '../../verticalRibbon/views';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';
import DivStyled from './styled';
import SubNavigation from '../component/subNavigation';
import FilterContent from '../../../components/filter/filter';
import queryString from 'query-string';
import { Loader } from '@/shared/components/loader/Loader';
import AdsBanner from '../../../components/adsBanner/views/index';
import './customise-col.scss';
import SearchYoutubePage from '../component/search';
import { withKeyByAccountId } from '@/core/hoc/withKeyByAccountId';

const RenderLoadingOverlay = props => {
  const { isLoadingPage } = props;
  if (isLoadingPage) {
    return (
      <div className="loadingOverlayContainer">
        <div className="loadingCenterFull">
          <Loader type="Oval" color="#FFFFFF" height="30" width="30" />
        </div>
      </div>
    );
  }
  return null;
};
const Body = React.memo(props => {
  const { isSearchPage, isLoadingSeason, seasonDetail, ribbonTarget, topTen, ribbons } = props.data;
  if (isSearchPage) {
    return (
      <div className="v-ytbPage v-ytbPage__search">
        <Col sm={12} xs={12} md={8} lg={8} className="v-ytbPage--leftSite">
          <SearchYoutubePage />
        </Col>
        <Col sm={12} xs={12} md={4} lg={4} className="v-ytbPage--rightSite">
          {ribbons && <VerticalRibon topTenLanding={topTen} youtube />}
        </Col>
      </div>
    );
  }
  if (!ribbonTarget && !seasonDetail) return null;
  return (
    <div className="v-ytbPage">
      <Col sm={12} xs={12} md={8} lg={8} className="v-ytbPage--leftSite">
        {!isLoadingSeason ? (
          <LeftSite season={seasonDetail} entityDetail={{ ribbonTarget }} />
        ) : (
          <div className="v-ytbPage--loading">
            <Loader type="Oval" color="#444444" height="30" width="30" />
          </div>
        )}
      </Col>
      <Col sm={12} xs={12} md={4} lg={4} className="v-ytbPage--rightSite">
        {ribbons && <VerticalRibon topTenLanding={topTen} youtube />}
      </Col>
    </div>
  );
});

const YoutubePage = props => {
  const {
    page,
    isFilterHide,
    isSubMenuHide,
    seasonDetail,
    listRibbonTarget,
    location,
    topTen,
    isLoadingSeason,
    match,
  } = props;
  const [ribbonTarget, setRibbon] = useState(null);
  const [isSearchPage, setSearchPage] = useState(false);
  const filterRef = useRef(null);
  const ribbons = page && (page.ribbons || null);
  let paramQuery = queryString.parse(props.location.search, { decode: false });
  let defaultRibbon = null;
  useEffect(() => {
    if (match.params && match.params.keyword) {
      setSearchPage(true);
      return;
    }
    if (!ribbons || (page && page.display_style !== 'YOUTUBE') || seasonDetail) return;
    window.document.body.classList.add('v-youtubePage');
    window.document.body.classList.remove('v-homePage');
    if (listRibbonTarget.length > 0) {
      defaultRibbon = listRibbonTarget[0];
    } else {
      if (!ribbons) {
        return;
      }
      defaultRibbon = ribbons[0];
      if (
        ribbons[0] &&
        Array.isArray(ribbons[0].items) &&
        !ribbons[0].items.length &&
        ribbons.length > 1
      )
        defaultRibbon = ribbons[1];
    }
    if (ribbonTarget && !paramQuery.category) {
      const locations = {
        pathname: location.pathname,
        search: `category=${ribbonTarget.slug}&${props.page.search}`,
      };
      props.history.replace(locations);
    }
    setSearchPage(false);
    setRibbon(defaultRibbon);
    return () => {
      window.document.body.classList.remove('v-youtubePage');
    };
  }, [ribbons, ribbonTarget, match.params && match.params.keyword]);

  return (
    <DivStyled className="v-ytbPage-container">
      {!isSubMenuHide && <SubNavigation />}
      {!isFilterHide && (
        <FilterContent ref={filterRef} page={page} selectBox visible hiddenFilter={isSearchPage} />
      )}
      {!isSubMenuHide && <AdsBanner position="top" />}
      <Body
        data={{
          isSearchPage,
          isLoadingSeason,
          seasonDetail,
          ribbonTarget,
          topTen,
          ribbons,
        }}
      />
      {!isSubMenuHide && <AdsBanner position="bottom" />}
      <RenderLoadingOverlay isLoadingPage={props.isLoadingPage} />
    </DivStyled>
  );
};

const mapStateToProps = state => ({
  page: state.youtubePage.page,
  listRibbonTarget: state.youtubePage.listRibbonTarget,
  isLoadingPage: state.youtubePage.isLoadingPage,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withKeyByAccountId(YoutubePage))),
);
