import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateHistoryFilter } from '../services';

function SearchContent(props) {
  const { t, history, location, match } = props;
  const [searchContent, setSearchContent] = useState('');
  const [pathName, setPathName] = useState(location.pathname);
  useEffect(() => {
    setPathName(`/youtube/${match.params.slug}`);
    setSearchContent(match.params.keyword);
  }, [match.params && match.params.keyword]);

  const handleSearch = e => {
    const value = e.target.value;
    setSearchContent(value);
  };

  const searchClick = e => {
    e.preventDefault();
    if (searchContent !== '') {
      if (e.type === 'keyup' && e.keycode !== 13) return;
      if (searchContent.trim() === '') return;
      let search = encodeURIComponent(searchContent);
      history.push(`${pathName}/search/${search}`);
    }
  };
  return (
    <form className="v-ytbPage--searchInput" onSubmit={e => searchClick(e)}>
      <i className="fa fa-search" aria-hidden="true" />
      <input
        type="text"
        value={searchContent}
        onChange={handleSearch}
        placeholder={t('navigation.search')}
      />
    </form>
  );
}

const mapStateToProps = state => ({
  filter: state.filter,
  filterHistory: state.youtubePage.filterHistory,
  page: state.home.page,
});

const mapDispatchToProps = {
  dpUpdateHistoryFilter: data => updateHistoryFilter(data),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchContent)),
);
